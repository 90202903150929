@import '../../_settings';

.AppBar {
    background-color: $md-default;
    z-index: 1000;
    display: flex;
    flex-direction: row;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    align-items: center;
    justify-content: space-between;

    .title {
        margin: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: 600;
        text-align: center;
        cursor: pointer;
        color: $md-primary;

        svg {
            vertical-align: middle;
            margin-left: 2px;
        }
    }

    .request_full_transcription {
        margin-right: 4px;
        padding: 2px;
    }

    .account_badge {
        flex: 1;
        justify-content: center;
        align-items: center;
        display: flex;
        border: 2px solid #e36a57;
        color: #e36a57;
        padding: 5px 8px 4px;
        border-radius: 40px;
        font-weight: 700;
        cursor: pointer;
        
        &:hover {
            background: #E36A57;
            color: #fff;
        }
        span {            
            font-size: 12px;
            white-space: nowrap;
        }
    }
  }