// Material variables
$body-font-size-base: 15px;

// AppBar Height
$app-bar-height-xs: 50px;
$app-bar-height-sm: 55px;
$app-bar-height-md: 60px;
$app-bar-height-lg: 64px;
$app-bar-height-xlg: 64px;

// ToolBar (Second AppBar) Height
$md-toolbar-height-xs: 35px;
$md-toolbar-height-sm: 40px;
$md-toolbar-height-md: 45px;
$md-toolbar-height-lg: 45px;
$md-toolbar-height-xlg: 50px;

// FontSize based on screen size
$font-size-xs: 12px;
$font-size-sm: 14px;
$font-size-md: 16px;
$font-size-lg: 18px;
$font-size-xlg: 20px;

// Footer size based on screen size
$app__footer-height-xs: 40px;
$app__footer-height-sm: 45px;
$app__footer-height-md: 50px;
$app__footer-height-lg: 55px;
$app__footer-height-xlg: 55px;

$md-default: #f7f7f7;
$md-primary: #005a50;
$md-secondary: #6c6e6e;
$md-color-danger: #e36a57; // rgb(221,44,0);
$md-gutter: 16px;
$md-light-text: #707273;
$md-line-height: 1.6em;
$md-subheader-bg: rgb(250, 250, 250);
$md-body-color: rgba(0, 0, 0, 0.87);
$md-icon-color: rgba(0, 0, 0, 0.54);
$md-input-color: $md-body-color;
$md-input-placeholder-color: rgba(0, 0, 0, 0.38);
$md-input-border-color: rgba(0, 0, 0, 0.12);

// Material UI@next
$MuiListItem-padding-vertical: 12px;
$MuiListItem-padding-horizontal: $md-gutter;

// Common variables
$md-brand-light: #f4f9fb;
$app__breakpoints: (
  xs: 600px,
  sm: 960px,
  md: 1280px,
  lg: 1920px,
);
$app__bg--light-accent: #fbfcfc;
$app-red: #e36a57;
$app-green: #5ec95f;
$app-yellow: #ee9a00;
$app-grey1: #a5acaf;
$app-grey2: #eaeaea;
$app-grey3: #f5f5f5;
$app-greydark: #979797;
$app-greydarker: #6d6e6e;
$app-btn-boxshadow: 0 0 $md-gutter rgba(0, 0, 0, 0.12);
$app__container-content-maxwidth: 960px;

// Components
$Header-bg: $md-default;
$Header-z-index: 1000;
$Header__button-circle-size: 34px;
$Header__button-circle-gutter: $md-gutter / 2;
$EditorControls-z-index: $Header-z-index - 10;

$WavePlayer__waveform-height-xs: 45px;
$WavePlayer__waveform-height-sm: 45px;
$WavePlayer__waveform-height-md: 50px;
$WavePlayer__waveform-height-lg: 50px;
$WavePlayer__waveform-height-xlg: 65px;

$WavePlayer-z-index: $EditorControls-z-index - 10;
$WavePlayer__video-height: 120px;
$WavePlayer__video-height-expanded: 180px;
$WavePlayer__video-height-large-screens: 200px;
$WavePlayer__video-height-large-screens-expanded: 300px;
$WavePlayer__video-background-color: #000000;
$WavePlayer__video-breakpoint: 1430px;

$Video-resize-transmition-time: 0.3s;
$Editor-background: #f4f4f4;
$Editor-foreground: #ffffff;
$Editor-spacing-bottom: $md-gutter * 8;
$Editor__segment-vertical-gutter: $md-gutter * 1.5;
$Editor__segment-horizontal-gutter: $md-gutter * 2;
$Editor__speaker-width: 150px;
$Editor__speaker-border: 2px;
$Editor__speaker-input-focus-bg: #fafafa;
$Editor__speaker-input-hover-color: #666;
$Editor__speaker-input-color: #999;
$Editor__speaker-input-bordercolor: #ccc;
$Editor__speaker-input-height: 24px;
$Editor__speaker-clear-width: 15px;
$Editor__avatar-height: 50px;
$Editor__word-color-inactive: #666;
$Editor__word-color-active: #000;
// $Editor__footer-height: $app__footer-height;
$Editor-maxwidth: 650px + $Editor__speaker-width + ($md-gutter * 4);

/**
 * Media query (min) mixin, see values at
 * https://material.angularjs.org/latest/layout/container
 */
@mixin resp($size) {
  @if $size == xs {
    @media (max-width: map-get($app__breakpoints, xs)) {
      @content;
    }
  }
  @if $size == sm {
    @media (min-width: map-get($app__breakpoints, xs)) and (max-width: map-get($app__breakpoints, sm)) {
      @content;
    }
  }
  @if $size == md {
    @media (min-width: map-get($app__breakpoints, sm)) and (max-width: map-get($app__breakpoints, md)) {
      @content;
    }
  }
  @if $size == lg {
    @media (min-width: map-get($app__breakpoints, md)) and (max-width: map-get($app__breakpoints, lg)) {
      @content;
    }
  }
  @if $size == xlg {
    @media (min-width: map-get($app__breakpoints, lg)) {
      @content;
    }
  }
}

@mixin CO__small-title($margin: false) {
  font-size: 15px;
  font-weight: 700;
  text-transform: uppercase;
  color: $md-primary;

  @if $margin {
    margin: $margin;
  } @else {
    margin: ($md-gutter / 2) 0 $md-gutter;
  }
}
