@import 'settings.scss';

@font-face {
  font-family: 'Geomanist';
  src: url('../src/assets/fonts/Geomanist-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'GeomanistBold';
  font-weight: bold;
  src: url('../src/assets/fonts/Geomanist-Bold.otf') format('opentype');
}

#root {
  display: flex;
  width: 100%;
}

html {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

body {
  display: flex;
  flex: 1;
  overflow: auto;
  background: #ECECEC;
  scroll-behavior: smooth;
  // height: calc(100% - #{$app-bar-height}); // fixed header
}

*:not(i) {
  font-family: 'Geomanist', sans-serif !important;
}
